<template>
  <div class="TopicManage">
    <div class="navC">话题管理</div>
    <div class="contentBox">
      <!-- 筛选 -->
      <div class="selectTop">
        <div class="lBox">
          <el-select v-model="form.search_status" placeholder="请选择状态" class="sid" style="width: 200px">
            <el-option v-for="item in optiones" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>

          <el-input v-model="form.search_title" placeholder="请输入话题关键字" class="input"></el-input>
          <el-input v-model="form.search_user" placeholder="请输入发起人姓名" class="input"></el-input>
        </div>
        <div class="rBox">
          <el-button type="primary" @click="init()">查询</el-button>
          <el-button @click="clearData()">重置</el-button>
        </div>
      </div>

      <!-- 表格 -->
      <div class="tableList">
        <el-table :data="tableData" :default-sort="{ prop: 'date', order: 'descending' }" style="width: 1054px">
          <el-table-column label="序号" align="center" width="110">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="title" label="话题" align="center" width="110" />
          <el-table-column prop="username" label="发起人" align="center" width="200" />
          <el-table-column prop="create_time" label="发起时间" sortable align="center" width="200" />
          <el-table-column prop="title_status" label="状态" align="center" width="200" />
          <el-table-column prop="date" label="操作" align="left" width="220">
            <template slot-scope="{ row }">
              <span v-if="row.title_status == '待审核'" @click="toDetail(row.id)">审核</span>
              <span v-if="
                  row.title_status == '未通过' || row.title_status == '通过'
                " @click="toDetail(row.id)">查看</span>
              <span v-if="
                  row.title_status == '未通过' || row.title_status == '通过'
                " @click="toDel(row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block" v-show="total">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="form.page"
          :page-size="form.limit" layout="prev, pager, next,jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      input: "",
      tableData: [
        {
          data: 1,
        },
      ],
      //总数
      total: 0,
      //表单数据
      form: {
        page: 1,
        limit: 10,
        search_status: "",
        search_title: "",
        search_user: "",
        teaching_studio_id: localStorage.getItem("studioId"),
      },
      value: "",
      optiones: [
        {
          id: 1,
          name: "待审核",
        },
        {
          id: 2,
          name: "通过",
        },
        {
          id: 3,
          name: "不通过",
        },
      ],
    };
  },
  created() {
    let rouName = this.$route.name
    this.$emit('rouname', rouName)
    this.init();
  },
  computed: {},
  methods: {
    //初始化
    init() {
      this.$axios
        .get("TeachingStudio/listGambit", {
          params: this.form,
        })
        .then((res) => {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
        });
    },

    //删除话题
    toDel(row) {
      this.$axios
        .delete(`TeachingStudio/gambit/${row.id}/${row.user_id}`)
        .then(() => {
          this.$message.success("删除话题成功");
          //初始化
          this.init();
        });
    },

    //跳转话题详情
    toDetail(id) {
      this.$router.push({
        path: "/TopicManageDetail",
        query: {
          id: id,
        },
      });
    },

    //重置
    clearData() {
      this.form.page = 1;
      this.form.limit = 10;
      this.form.search_status = "";
      this.form.search_title = "";
      this.form.search_user = "";
      this.init();
    },

    handleSizeChange(val) {
      this.form.limit = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.init();
    },
  },
};
</script>
<style lang="less" scoped>
.TopicManage {
  .navC {
    width: 100%;
    height: 60px;
    padding-left: 26px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
  }
  //内容盒子
  .contentBox {
    padding: 26px 26px;
    .selectTop {
      display: flex;
      .lBox {
        .input {
          width: 186px;
          margin-left: 10px;
        }
      }
      .rBox {
        margin-left: 30px;
        .btn {
          width: 102px;
          height: 34px;
          background: #ff8201;
          border: 1px solid #ff8201;
          border-radius: 4px;
          display: flex;
          align-items: center;
          color: #fff;
        }
      }
    }

    //表格
    .tableList {
      width: 1054px;
      margin-top: 24px;
      border: 1px solid #ebeef5;
      border-bottom: none;
      span {
        margin-right: 30px;
        color: #3489fe;
        cursor: pointer;
      }
    }
    //样式
    .block {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
  }
}
</style>
