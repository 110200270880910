import { render, staticRenderFns } from "./TopicManage.vue?vue&type=template&id=3769fcb5&scoped=true"
import script from "./TopicManage.vue?vue&type=script&lang=js"
export * from "./TopicManage.vue?vue&type=script&lang=js"
import style0 from "./TopicManage.vue?vue&type=style&index=0&id=3769fcb5&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3769fcb5",
  null
  
)

export default component.exports